/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {HrefLangManager, ResponsiveImg, LandingPageCta, ThreeColCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!ResponsiveImg) _missingMdxReference("ResponsiveImg", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/about-us/'], ['en', 'https://www.hear.com/about-us/'], ['en-US', 'https://www.hear.com/about-us/'], ['en-IN', 'https://www.hear.com/in/about-us/'], ['en-ZA', 'https://www.hear.com/za/about/'], ['en-MY', 'https://www.hear.com/my/about-us/'], ['ko-KR', 'https://www.hear.com/kr/about-hearcom'], ['en-CA', 'https://ca.hear.com/about-us/']]
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Revolutionizing hearing care is what we do. Because we believe that everyone on the planet should hear well to live well, we’re driven to make hearing care accessible and affordable around the world."), "\n", React.createElement(ResponsiveImg, {
    mobileSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/about-us-timeline-mobile.png",
    desktopSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/about-us-timeline-desktop.png",
    alt: "Hear.com Yearly Timeline"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "mission-driven",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#mission-driven",
    "aria-label": "mission driven permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Mission Driven"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our mission to advance hearing care and deliver the magic of hearing keeps us striving for more. Our customers thrive because we’re laser-focused on keeping them happy, healthy, sharp, and connected — ensuring their experience is always second to none."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Because hearing is essential to living your best life — it brings us freedom, youthfulness, happiness and a feeling of connection to the things that matter most…"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "A grandchild giggling for the first time"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Effortless conversations at family cookouts"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Leaves crunching beneath your feet on a cool fall hike"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Hearing an inspirational speech so crystal clear that you feel like you’re the only one in the room"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "a-culture-of-caring",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#a-culture-of-caring",
    "aria-label": "a culture of caring permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "A Culture of Caring"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our “never corporate” and “customer first” approach encourages everyone at hear.com to act like an owner and do what’s best for our customers. It motivates our employees to not only work hard, but to be passionate about delivering the best possible experience for our customers."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The diverse individuals who work at hear.com are hard workers. But most of all, they have heart. And they’re doing more than a ‘job.’ They’re part of something that’s truly changing lives all around the world. Everyone at hear.com is all about helping people hear well so they can live well."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "But don’t just take our word for it — head over to our ", React.createElement(_components.a, {
    href: "/about-us/testimonials/",
    className: "c-md-a"
  }, "testimonials"), " page to hear what people are saying about their experience with hear.com and their renewed quality of life."), "\n", React.createElement("div", {
    className: "c-about-us-credentials"
  }, React.createElement("div", {
    className: "c-about-us-credentials__col c-about-us-credentials__col--img"
  }, React.createElement("img", {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/cup.svg",
    alt: "",
    className: "c-about-us-credentials__cup",
    loading: "lazy"
  })), React.createElement("div", {
    className: "c-about-us-credentials__col"
  }, React.createElement("h4", {
    className: "c-about-us-credentials__lead"
  }, "Hearing Success Program", React.createElement("sup", null, "®"), React.createElement("br"), React.createElement("em", null, "Your Best Outcome, Guaranteed")), "\n", React.createElement("p", {
    className: "c-about-us-credentials__copy"
  }, "10 years of research and a focus on personalized care inspired us to produce the industry's first proven approach to hearing success."))), "\n", React.createElement("div", {
    className: "c-about-us-credentials"
  }, React.createElement("div", {
    className: "c-about-us-credentials__col c-about-us-credentials__col--img"
  }, React.createElement("img", {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/people.svg",
    alt: "",
    className: "c-about-us-credentials__people",
    loading: "lazy"
  })), React.createElement("div", {
    className: "c-about-us-credentials__col"
  }, React.createElement("h4", {
    className: "c-about-us-credentials__lead"
  }, "Over 1 Million Helped", React.createElement("br"), React.createElement("em", null, "A Human Approach to Hearing")), "\n", React.createElement("p", {
    className: "c-about-us-credentials__copy"
  }, "We put you first, always. Our passion and partnership with a network of the best local professionals is why our customers are twice as happy as the industry average."))), "\n", React.createElement("div", {
    className: "c-about-us-credentials"
  }, React.createElement("div", {
    className: "c-about-us-credentials__col c-about-us-credentials__col--img"
  }, React.createElement("img", {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/lightbulb.svg",
    alt: "",
    className: "c-about-us-credentials__light",
    loading: "lazy"
  })), React.createElement("div", {
    className: "c-about-us-credentials__col"
  }, React.createElement("h4", {
    className: "c-about-us-credentials__lead"
  }, "Innovators at Our Core", React.createElement("br"), React.createElement("em", null, "We Are Cutting-Edge Hearing Care")), "\n", React.createElement("p", {
    className: "c-about-us-credentials__copy"
  }, "Founder-led and employee-owned, we’re constantly striving to do better. We’re pioneers in digital, invisible, and remote hearing care — and we’re just getting started."))), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "process-to-achieving-better-hearing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#process-to-achieving-better-hearing",
    "aria-label": "process to achieving better hearing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Process to achieving better hearing"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our process for achieving better hearing begins with a conversation with one of our experienced experts, who will guide you through every step of the journey. Next, you'll undergo a comprehensive hearing test administered by one of our top 2% audiologists in the USA, ensuring the most accurate assessment of your hearing needs. Once we've determined the best solution for you, you'll have the opportunity to test our state-of-the-art hearing aids risk-free for 45 days. This allows you to experience firsthand the transformative effects of improved hearing in your daily life, with the confidence that you're making the right choice for your long-term comfort and well-being. Learn more ", React.createElement(_components.a, {
    href: "/about-us/how-it-works/",
    className: "c-md-a"
  }, "here")), "\n", React.createElement(LandingPageCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
